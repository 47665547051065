import React from 'react';
import Header from '../components/HeaderComponent';
import PlanMyTrip from '../components/PlanMyTripComponent';
import Footer from '../components/FooterComponent';

import MainPageMetadata from '../metadata/MainPageMetadata';

import Firebase from '../components/Firebase/Firebase';
import FirebaseContext from '../components/Firebase/FirebaseContext';

function PlanMyTripPage(props) {
    return (
        <div>
            <MainPageMetadata pathname={ props.location.pathname } />
            <Header />
            <FirebaseContext.Provider value={ new Firebase() }>
                <PlanMyTrip />
            </FirebaseContext.Provider>
            <Footer />
        </div>
    );
}

export default PlanMyTripPage;
