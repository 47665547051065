import React, { Component } from 'react';
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LocalForm, Control, Errors, actions } from 'react-redux-form';

import FirebaseContext from './Firebase/FirebaseContext';
import { uploadString } from "firebase/storage";

const required = (val) => val && val.length;
const minLength = (len) => (val) => !(val) || (val.length >= len);
const validEmail = (val) => !(val) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    handleSubmitForm(values, firebase) {
        var request = JSON.stringify(values).toString();
        var fileName = Math.round(Math.random() * 10000000000000);

        var requestsRef = firebase.getAppStorage('requests/' + fileName.toString() + '.txt');
        uploadString(requestsRef, request)
            .then(snapshot => {
                this.toggleModal();
                this.formDispatch(actions.reset('request'));

            }, err => {
                this.handleSubmitForm(values);
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {
        const PlanMyTripForm = ({ firebase }) => {
            return (
                <LocalForm model="request" onSubmit={(values) => this.handleSubmitForm(values, firebase)}
                    getDispatch={(dispatch) => this.formDispatch = dispatch} >
                    <div style={{ textAlign: 'left' }}>
                        <div className="row form-row" style={{ marginBottom: '20px' }}>
                            <div className="form-group col-12 col-md-6">
                                <Label className="col-12 form-label" htmlFor="name">Name</Label>
                                <div className="col-12">
                                    <Control.text model=".name" id="name"
                                        className="form-control" validators={{required, minLength: minLength(3)}} />
                                    <Errors
                                        className="text-danger" model=".name" show="touched"
                                        messages={{
                                            required: "Required field.",
                                            minLength: "Name must be greater than 2 characters."
                                        }} />
                                </div>
                            </div>

                            <div className="form-group col-12 col-md-6">
                                <Label className="col-12 form-label" for="email">Email</Label>
                                <div className="col-12">
                                    <Control.text model=".email" id="email" name="email"
                                        className="form-control" validators={{required, validEmail}} />
                                    <Errors
                                        className="text-danger" model=".email" show="touched"
                                        messages={{
                                            required: "Required field.",
                                            validEmail: "Invalid email address."
                                        }} />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginBottom: '40px' }}>
                            <div className="form-group">
                                <Label className="col-12 form-label" for="country">Country of residence</Label>
                                <div className="col-12">
                                    <Control.text model=".country" id="country" name="country"
                                        className="form-control" validators={{required}} />
                                    <Errors
                                        className="text-danger" model=".country" show="touched"
                                        messages={{
                                            required: "Required field."
                                        }} />
                                </div>
                            </div>
                        </div>

                        <div style={{ marginBottom: '40px' }} >
                            <div className="form-group">
                                <Label className="col-12 form-label" for="where">Tell us Where and When you plan to travel.</Label>
                                <div className="col-12">
                                    <Control.textarea model=".where" id="where" name="where" rows="3"
                                        className="form-control" validators={{ required }} />
                                    <Errors
                                        className="text-danger" model=".where" show="touched"
                                        messages={{
                                            required: "Required field."
                                        }} />
                                </div>
                            </div>
                        </div>

                        <div className="form-group" style={{ marginBottom: '20px' }}>
                            <Label className="col-12 form-label" for="details">Describe the details you have on your trip.</Label>
                            <div className="col-12">
                                <Control.textarea model=".details" id="details" name="details" rows="5"
                                    placeholder="Tell us how many people are traveling with you, how much is your budget, and what you are looking for on this trip."
                                    className="form-control" validators={{ required }} />
                                <Errors
                                    className="text-danger" model=".details" show="touched"
                                    messages={{
                                        required: "Required field."
                                    }} />
                            </div>
                        </div>
                    </div>
                    <Button color="primary"  className="button-submit" type="submit">Send</Button>
                </LocalForm>
            )
        }

        return (
            <div>
                <div className="container">
                    <div className="city-header">
                        <h1>Let Us Plan Your Next Adventure</h1>
                        <div className="col-12 col-lg-10 m-auto">
                            <p style={{ textAlign: 'justify' }}>
                                We have spent the past few years traveling to many places around the world, carefully planing our trips to get to best out of them on the short time we have, and always keeping a look on the budget.
                                After all our experiences, we believe now it is time for us to help others get the <b>best</b> out of their holidays.
                                <br/><br/>
                                Fill in the short form bellow and tell us what you are looking for on your next adventure.
                                We will create an itinerary suited for all your needs, and will enter in contact with you over the details on the budget.
                                You can add any changes you want to the itinerary and we will redefine it until you are <b>satisfied</b> with the result.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 col-lg-10 ms-auto me-auto">
                        <FirebaseContext.Consumer>
                            {(firebase) => {
                                return( <PlanMyTripForm firebase={ firebase } /> )
                            }}
                        </FirebaseContext.Consumer>
                    </div>
                </div>
                <Modal isOpen={ this.state.isModalOpen } toggle={ this.toggleModal } centered>
                    <ModalHeader toggle={ this.toggleModal }>Request Sent Successfully!</ModalHeader>
                    <ModalBody toggle={ this.toggleModal }>
                        Your request is being reviewed.<br/> We will enter in contact with you soon to further discuss details of your tip.
                    </ModalBody>
                    <ModalFooter>
                          <Button color="secondary" onClick={ this.toggleModal }>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ContactUs;
